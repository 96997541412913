import { Copy } from "components/Copy";
import { Image } from "components/Image";
import Link from "components/Link";
import { t } from "locales";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
const qrCodeSize = 240;
export default function QRCode({
  url,
  show
}: {
  url: string;
  show: boolean;
}) {
  const theme = useTheme();
  const [imgDataUrl, setImgDataUrl] = useState("");
  useEffect(() => {
    const setDataUrl = async () => {
      const qrCodeURL = new URL(url);
      qrCodeURL.searchParams.set("rel", "qrcode");
      const code = (await import("qrcode")).default;
      setImgDataUrl(await code.toDataURL(qrCodeURL.toString(), {
        color: {
          dark: theme.palette.bhc[800]
        },
        margin: 0,
        width: 164
      }));
    };
    setDataUrl();
  }, [url, theme.palette.bhc]);
  const copyLink = new URL(url);
  copyLink.searchParams.set("rel", "copylink");
  return <StyledContainer show={show.toString()} container justifyContent="center" data-sentry-element="StyledContainer" data-sentry-component="QRCode" data-sentry-source-file="QRCode.tsx">
            {imgDataUrl && <StyledImage alt="QR" height={qrCodeSize} width={qrCodeSize} loading="lazy" src={imgDataUrl} />}
            <Grid container item xs data-sentry-element="Grid" data-sentry-source-file="QRCode.tsx">
                <Grid item component={Typography} variant="subtitle1" xs={12} data-sentry-element="Grid" data-sentry-source-file="QRCode.tsx">
                    {t.qr.header}
                </Grid>
                <Grid item component={Typography} xs={12} data-sentry-element="Grid" data-sentry-source-file="QRCode.tsx">
                    {t.qr.body}
                </Grid>
                <StyledLink href={url} data-sentry-element="StyledLink" data-sentry-source-file="QRCode.tsx">{url}</StyledLink>
                <Grid container item className="no-print" justifyContent="flex-end" data-sentry-element="Grid" data-sentry-source-file="QRCode.tsx">
                    <Copy value={copyLink.toString()} data-sentry-element="Copy" data-sentry-source-file="QRCode.tsx" />
                </Grid>
            </Grid>
        </StyledContainer>;
}
const StyledContainer = styled(Grid)<{
  show: string;
}>(({
  theme,
  show
}) => {
  const {
    breakpoints,
    palette,
    spacing
  } = theme;
  return {
    display: show === "true" ? "flex" : "none",
    marginTop: spacing(),
    [breakpoints.up("sm")]: {
      border: `1px solid ${palette.cds[700]}`,
      borderRadius: 2
    },
    "& > *": {
      padding: spacing()
    },
    "@media print": {
      display: "flex"
    }
  };
});
const StyledLink = styled(Link)(({
  theme
}) => ({
  color: theme.palette.cds[700],
  fontSize: "1rem",
  margin: theme.spacing(1, 0),
  maxWidth: "60ch",
  lineBreak: "anywhere",
  "@media print": {
    color: "inherit"
  }
}));
const StyledImage = styled(Image)(({
  theme
}) => ({
  maxHeight: theme.spacing(30),
  "@media print": {
    filter: "grayscale(100%)",
    height: theme.spacing(19),
    width: theme.spacing(19)
  }
}));