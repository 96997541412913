import { Icon } from "components/Icon";
import { useInteractions } from "components/interactions/InteractionsContext";
import { general } from "config/config";
import useUser from "hooks/useUser";
import { useRouter } from "next/router";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import { isEditor } from "auth/authorization";
export function PreviewFAB() {
  const router = useRouter();
  const {
    isPreview,
    asPath
  } = router;
  const preview = isPreview || asPath.includes("preview");
  const {
    user
  } = useUser();
  const theme = useTheme();
  if (!isEditor(user?.roles)) {
    return null;
  }
  let href;
  if (preview) {
    if (asPath === "/preview") href = "/";else href = asPath.replace("/preview", "");
  } else {
    href = `/preview${asPath}`;
  }
  return <Tooltip title={preview ? "Show published" : "Show unpublished"} data-sentry-element="Tooltip" data-sentry-component="PreviewFAB" data-sentry-source-file="EditorInteractions.tsx">
            <StyledPrewFab href={href} size="medium" data-sentry-element="StyledPrewFab" data-sentry-source-file="EditorInteractions.tsx">
                <Icon name="visibility" fontSize="small" htmlColor={theme.palette.cms} data-sentry-element="Icon" data-sentry-source-file="EditorInteractions.tsx" />
            </StyledPrewFab>
        </Tooltip>;
}
const StyledPrewFab = styled(Fab)(({
  theme
}) => ({
  backgroundColor: theme.palette.common.white
}));
export function EditFAB() {
  const theme = useTheme();
  const {
    contentId
  } = useInteractions();
  const {
    user
  } = useUser();
  if (!isEditor(user?.roles) || !contentId) {
    return null;
  }
  const url = new URL(general.appHost);
  const editUrl = `https://cms.${url.host}/editor/edit/${contentId}`;
  return <Tooltip title="Edit in CMS" data-sentry-element="Tooltip" data-sentry-component="EditFAB" data-sentry-source-file="EditorInteractions.tsx">
            <StyledEditFab href={editUrl} size="medium" data-sentry-element="StyledEditFab" data-sentry-source-file="EditorInteractions.tsx">
                <Icon name="edit" fontSize="small" htmlColor={theme.palette.common.white} data-sentry-element="Icon" data-sentry-source-file="EditorInteractions.tsx" />
            </StyledEditFab>
        </Tooltip>;
}
const StyledEditFab = styled(Fab)(({
  theme
}) => ({
  backgroundColor: theme.palette.cms
}));
export function DebugFAB() {
  const theme = useTheme();
  const {
    user
  } = useUser();
  const {
    openDebug
  } = useInteractions();
  if (!isEditor(user?.roles)) {
    return null;
  }
  return <Tooltip title="Toggle debug drawer" data-sentry-element="Tooltip" data-sentry-component="DebugFAB" data-sentry-source-file="EditorInteractions.tsx">
            <StyledDebugFab onClick={openDebug} size="medium" data-sentry-element="StyledDebugFab" data-sentry-source-file="EditorInteractions.tsx">
                <Icon fontSize="small" htmlColor={theme.palette.common.white} name="settings" data-sentry-element="Icon" data-sentry-source-file="EditorInteractions.tsx" />
            </StyledDebugFab>
        </Tooltip>;
}
const StyledDebugFab = styled(Fab)(({
  theme
}) => ({
  backgroundColor: theme.palette.error.main
}));