import { Icon } from "components/Icon";
import SearchCombobox from "components/Layout/Header/SearchCombobox";
import SkipNav from "components/SkipNav";
import { rewrites } from "config/config";
import useDebounce from "hooks/useDebounce";
import { t } from "locales";
import { NextRouter, useRouter } from "next/router";
import { FormEvent, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ISearchSuggestionsAndAutocomlete, useGetSearchSuggestions } from "data/contentData/hooks/search.hooks";
import { isQueryValid, normalizeQuery } from "lib/string/query-validation";
export interface SuggestionOrTermOption {
  group: "history" | "term" | string;
  name: string;
  topic?: string;
  type?: string;
  id?: string;
  url: string;
}
function useQuery(router: NextRouter) {
  const [q, setQ] = useState<string>(() => router.query.q as string ?? "");
  useEffect(() => {
    if (router.query.q) {
      setQ(router.query.q as string);
    }
  }, [router.query.q]);
  const debouncedQuery = useDebounce(q, 200);
  return {
    q,
    setQ,
    debouncedQuery
  };
}
function useFormatSuggestions(data: ISearchSuggestionsAndAutocomlete | undefined) {
  // state, suggestion
  const [suggestions, setSuggestions] = useState<SuggestionOrTermOption[]>([]);

  // every time data changes, format data
  useEffect(() => {
    if (!data) {
      return;
    }
    const {
      autocomplete,
      suggestionGroups
    } = data;
    const newOptions: any[] = [];
    if (autocomplete.length > 0) {
      newOptions.push({
        type: "subheader",
        name: t["search"]["improve-search"],
        group: "term",
        id: `subheader-${t["search"]["improve-search"]}`
      }, ...autocomplete.map((term, index) => ({
        group: "term",
        name: term,
        type: "suggestion",
        id: `button-term-${term}-${index}`
      })));
    }
    suggestionGroups.forEach((suggestionGroup, groupIndex) => {
      newOptions.push({
        type: "subheader",
        name: suggestionGroup.name,
        group: suggestionGroup.name,
        id: `subheader-${suggestionGroup.name}-${groupIndex}`
      }, ...suggestionGroup.suggestions.map((suggestion, suggestionIndex) => ({
        group: suggestionGroup.name,
        type: "suggestion",
        id: `button-${suggestionGroup.name}-${suggestion.name}-${suggestionIndex}`,
        ...suggestion
      })));
    });
    setSuggestions(newOptions);
  }, [data, setSuggestions]);
  const resetOptions = () => {
    setSuggestions([]);
  };
  return {
    suggestions,
    resetOptions
  };
}

/** The number of last searches saved on the user */
export default function SearchField() {
  const router = useRouter();
  const mdUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const [focusedSearch, setFocusedSearch] = useState(false);
  const {
    q,
    setQ,
    debouncedQuery
  } = useQuery(router);
  const {
    status,
    data
  } = useGetSearchSuggestions(normalizeQuery(debouncedQuery, "suggestion"), {
    enabled: isQueryValid(debouncedQuery, "suggestion")
  });
  const {
    suggestions,
    resetOptions
  } = useFormatSuggestions(data);
  const pathname = router.pathname.startsWith("/search-dev") ? "/search-dev" : rewrites["/search"];
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isQueryValid(q, "search")) {
      return;
    }
    await router.push({
      pathname,
      query: {
        q
      }
    });
  };
  const handleOnSelect = async (value: string | SuggestionOrTermOption | null) => {
    if (value === null) {
      return resetOptions();
    }
    if (typeof value === "string" || value.group === "term") {
      const _q = typeof value === "string" ? value : value.name;
      if (!isQueryValid(_q, "suggestion")) {
        return;
      }
      await router.push({
        pathname,
        query: {
          q: _q
        }
      });
    } else {
      await router.push(value.url);
    }
  };
  const handleInputChange = (value: string) => {
    setQ(value);
  };
  return <StyledGrid onFocus={() => setFocusedSearch(true)} onBlur={() => setFocusedSearch(false)} focused={focusedSearch.toString()} data-sentry-element="StyledGrid" data-sentry-component="SearchField" data-sentry-source-file="SearchField.tsx">
            <Grid container alignItems="center" component="form" onSubmit={handleSubmit} data-sentry-element="Grid" data-sentry-source-file="SearchField.tsx">
                <Grid item xs data-sentry-element="Grid" data-sentry-source-file="SearchField.tsx">
                    <SearchCombobox suggestions={suggestions} onChange={handleInputChange} onSelect={handleOnSelect} status={status} initialValue={router.query.q?.toString()} data-sentry-element="SearchCombobox" data-sentry-source-file="SearchField.tsx" />
                </Grid>
                <StyledIconButton aria-label={t.search.search} type="submit" size="small" data-sentry-element="StyledIconButton" data-sentry-source-file="SearchField.tsx">
                    <Icon name="search" data-sentry-element="Icon" data-sentry-source-file="SearchField.tsx" />
                </StyledIconButton>
                {mdUp && <SkipNav />}
            </Grid>
        </StyledGrid>;
}
const StyledGrid = styled(Grid)<{
  focused: string;
}>(({
  theme,
  focused
}) => ({
  position: "relative",
  margin: theme.spacing(1),
  padding: 0,
  backgroundColor: theme.palette.common.white,
  borderRadius: "6px",
  outline: focused === "true" ? `3px solid ${theme.palette.cds[700]}` : "none"
}));
const StyledIconButton = styled(IconButton)(({
  theme
}) => ({
  padding: theme.spacing(1),
  color: theme.palette.cds[700]
}));