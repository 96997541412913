import { PortableTextTypeComponentProps } from "@portabletext/react";
import Link from "components/Link";
import usePath from "hooks/usePath";
import { MouseEvent, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import { IReference } from "data/contentData/api/content";
import { useGetPagePublic } from "data/contentData/hooks/content.hooks";
interface ReferenceInlineProps {
  ids: number[];
}
function useReferences(ids?: string[]): IReference[] {
  const url = usePath();
  const {
    data: page
  } = useGetPagePublic(url);
  const references = page?.references ?? [];
  if (ids) {
    return (references as IReference[]).filter(reference => ids.includes(reference.referenceId));
  } else {
    return references;
  }
}
function getReferenceText(ids: number[]) {
  function isOneIncreasingSequence(numbers: number[]) {
    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] !== numbers[i - 1] + 1) {
        return false;
      }
    }
    return true;
  }
  if (ids.length === 1) {
    return ids[0];
  } else if (isOneIncreasingSequence(ids)) {
    return `${ids[0]}-${ids[ids.length - 1]}`;
  }
  return ids.join(", ");
}
export default function ReferenceInline(props: PortableTextTypeComponentProps<ReferenceInlineProps>) {
  const {
    ids
  } = props.value;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const _ids = ids.map(id => `reference-${id}`);
  const references = useReferences(_ids);
  const open = Boolean(anchorEl);
  const id = open ? "reference-popover" : undefined;
  return <>
            &nbsp;
            <Sup aria-describedby={id} onClick={handleOpen} onKeyUp={(e: any) => e.key === "Enter" && handleOpen(e)} tabIndex={0} role="button" data-sentry-element="Sup" data-sentry-source-file="ReferenceInline.tsx">
                {getReferenceText(ids)}
            </Sup>
            <Popover anchorEl={anchorEl} anchorOrigin={{
      horizontal: "right",
      vertical: "center"
    }} id={id} onClick={event => {
      event.stopPropagation();
    }} onClose={handleClose} open={open} PaperProps={{
      square: true
    }} transformOrigin={{
      horizontal: "left",
      vertical: "center"
    }} data-sentry-element="Popover" data-sentry-source-file="ReferenceInline.tsx">
                <Paper data-sentry-element="Paper" data-sentry-source-file="ReferenceInline.tsx">
                    <List data-sentry-element="List" data-sentry-source-file="ReferenceInline.tsx">
                        {references.map((reference, index) => <StyledListItem key={reference.referenceId} divider={index + 1 < references.length}>
                                {reference.text}
                                {reference.link && <Link display="block" href={reference.link.href} title={reference.link.title}>
                                        {`${reference.link.text ?? new URL(reference.link.href).host} `}
                                    </Link>}
                            </StyledListItem>)}
                    </List>
                </Paper>
            </Popover>
        </>;
}
const Sup = styled("sup")(({
  theme
}) => ({
  border: "2px solid transparent",
  boxSizing: "border-box",
  color: theme.palette.cds[700],
  margin: theme.spacing(-1, -0.5, -0.5, -0.5),
  padding: theme.spacing(0.5),
  whiteSpace: "nowrap",
  cursor: "pointer",
  "&:hover, &:focus": {
    outline: `3px solid ${theme.palette.bhc[900]}`,
    background: "white",
    fontWeight: 700
  }
}));
const StyledListItem = styled(ListItem)(() => ({
  maxWidth: 320,
  flexDirection: "column",
  alignItems: "flex-start"
}));