import ConfirmUserDetailsForm from "components/ConfirmUserDetails/ConfirmUserDetailsForm";
import { general } from "config/config";
import { differenceInDays, parseISO } from "date-fns";
import useUser from "hooks/useUser";
import { t } from "locales";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { IProfile } from "data/customerManager/api/profile";
import { useGetProfile } from "data/customerManager/hooks/profile.hooks";
import theme from "lib/theme";
export const PHYSICIAN_PROFESSION_ID = 2;
export const OTHER_HEALTH_PERSONNEL_PROFESSION_ID = 5;
export const STUDENT_PROFESSION_ID = 4;
export const DAYS_BETWEEN_CONFIRM_USER_DETAILS = 30 * 6;
function showConfirmUserDetails(authenticated: boolean, profile: IProfile | null | undefined) {
  if (!authenticated) {
    return false;
  }
  if (!profile) {
    return false;
  }
  if (!general.enableConfirmUserDetails) {
    return false;
  }
  const lastShown = profile.lastProfileUpdate === null ? new Date(2000, 1, 1) : parseISO(profile.lastProfileUpdate);
  const days = differenceInDays(new Date(), lastShown);
  if (days < DAYS_BETWEEN_CONFIRM_USER_DETAILS) {
    return false;
  }
  return true;
}
export default function ConfirmUserDetails() {
  const {
    authenticated
  } = useUser();
  const {
    data: profile
  } = useGetProfile();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const showDialog = showConfirmUserDetails(authenticated, profile);
  if (!showDialog || !profile) {
    return null;
  }
  return <Dialog maxWidth="sm" fullWidth open={open} data-sentry-element="Dialog" data-sentry-component="ConfirmUserDetails" data-sentry-source-file="ConfirmUserDetails.tsx">
            <DialogTitle bgcolor={theme.palette.bhc[800]} color={theme.palette.grey.white} data-sentry-element="DialogTitle" data-sentry-source-file="ConfirmUserDetails.tsx">
                <Typography variant="h1" component="span" data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetails.tsx">
                    {t["user-details"]["confirm"]}
                </Typography>
            </DialogTitle>
            <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="ConfirmUserDetails.tsx">
                <ConfirmUserDetailsForm profile={profile} handleClose={handleClose} data-sentry-element="ConfirmUserDetailsForm" data-sentry-source-file="ConfirmUserDetails.tsx" />
            </DialogContent>
        </Dialog>;
}